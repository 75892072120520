import { IAppointment } from "../../types/models/Appointment";
import { formatDateTime } from "../date-time/dateTimeHelpers";

export const getOnlinePaymentCSVContent = (appointmentList: IAppointment[]) => {
  
    const headers = "Date, Treatment, Price, Paid Amount, Refund Amount, Client Name, Payment Method";

    const onlinePaidAppointments = appointmentList.filter(appointment => {
        return appointment.paymentList?.some(item => item.online);
    });

    const data = onlinePaidAppointments.map(appointment => {

        const { 
            treatment,
            clientName,
            paymentList,
            refund
        } = appointment;

        const { day, month, year } = formatDateTime(new Date(appointment.appointment_date * 1000));
        const date = `${day} ${month} ${year}`;

        const price = `${treatment?.price.toFixed(2)}`;
        
        const paymentMadeOnline = paymentList?.find(item => item.online);

        const method = paymentMadeOnline?.method;

        const newPaidAmount = `${paymentMadeOnline?.amount.toFixed(2)}`;
        const newRefundAmount = `${refund?.amount ? refund.amount.toFixed(2) : 0}`

        return `${date}, ${treatment?.title}, ${price}, ${newPaidAmount}, ${newRefundAmount}, ${clientName}, ${method}`;

    });

    return `${headers}\n${data.join("\n")}`;

}