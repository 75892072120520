import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { ITreatment } from "../../../types/models/Treatment";
import { AvailableDateTimes } from "../../../types/pages/Calendar";
import { updateState } from "../../../store/calendar/calendarReducer";
import styles from "../common/Others.module.css";

const FindTimesButton: React.FC = () => {

    const { tenantId } = useParams();
  
    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { newAppointmentInfo } = calendarState;
    const { selectedLocation, selectedStylist } = newAppointmentInfo;
    const selectedTreatment = newAppointmentInfo.selectedTreatment as ITreatment;

    const findAvailableStartTimes = async() => {

        const treatmentList = [{
            treatmentDuration: selectedTreatment.duration,
            choosenStylistId: selectedStylist?._id
        }];

        const location = {
            _id: selectedLocation?._id,
            title: selectedLocation?.title
        };

        const params = `location=${JSON.stringify(location)}&treatmentList=${JSON.stringify(treatmentList)}`
        const endpoint = config.url.BACKEND_API_URL + `/appointments/${tenantId}/stylist-available-times?${params}`;

        try {

            const response = await axios.get(endpoint);
            const newAvailableDateTimes: AvailableDateTimes[] = response.data.availableDateTimes;
            dispatch(updateState({
                name: "availableDateTimes",
                value: newAvailableDateTimes
            }))

        } catch(error) {
            alert("Something went wrong");
        }

    }

    return (
        <div className = {styles.find_times_button}>
            <button onClick = {findAvailableStartTimes}>
                Find Available Start Times
            </button>
        </div>
    );

}

export default FindTimesButton;