import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import {
    AppointmentType,
    AppointmentDiscountType 
} from "../../../types/models/Appointment";
import { updateState } from "../../../store/calendar/calendarReducer";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { useFetchSingleAppointment } from "../../../hooks/backend_api/appointments/useFetchSingleAppointment";
import { getUserAccess } from "../../../helpers/calendar/getUserAccess";
import styles from "./AppointmentModal.module.css";
import { Modal, ModalBackground, ModalContent } from "../../../components/common/Modal";
import Header from "./Header";
import DeleteButton from "./DeleteButton";
import ClientDetails from "./ClientDetails";
import AppointmentDetails from "./AppointmentDetails";
import AppointmentNote from "./AppointmentNote";
import PaymentStatus from "./PaymentStatus";
import CouponGiftCard from "./CouponGiftCard";

const AppointmentModal: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const userState = useSelector((state: RootState) => state.userState);
    const { paymentAmount, appointmentList, appointmentId, appointmentInAction } = calendarState;
    const { user } = userState;
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { loading, errorMsg } = useFetchSingleAppointment(appointmentId as string)

    const totalPaidAmount = appointmentInAction?.paymentList?.reduce((total, payment) => {
        return total + payment.amount
    }, 0);
    const isPaid = totalPaidAmount === appointmentInAction?.priceAfterDiscount;
    const isDiscountedByCoupon = appointmentInAction?.discountList?.some(item => {
        return item.type === AppointmentDiscountType.Coupon;
    });
    const isDiscountedByGiftCard = appointmentInAction?.discountList?.some(item => {
        return item.type === AppointmentDiscountType.GiftCard;
    });

    const closeModal = () =>  {

        dispatch(updateState({
            name: "appointmentInAction",
            value: null
        }))

        dispatch(updateState({
            name: "appointmentId",
            value: null
        }))

        // Because we are auto-filling make payment amount in the appointmentInAction modal if
        // the appointmentInAction was partially paid before.
        if(paymentAmount) {
            dispatch(updateState({
                name: "paymentAmount",
                value: ""
            }));
        }
       
    };

    const { 
        canUserMaintainAgendas, 
        canViewClientInfo 
    } = useMemo(() => getUserAccess(user, appointmentInAction), [user, appointmentInAction])

    return (
        <Modal>
            <ModalBackground />
            <ModalContent
                width = {isLargeDevice ? "50%" : "95%"}
                height="90%"
                padding="0px"
            >
                {
                    loading
                    ?
                    <div>loading...</div>
                    :
                    errorMsg
                    ?
                    <div>{errorMsg}</div>
                    :
                    <div className = {styles.appointment_modal}>
                        <Header 
                            appointment={appointmentInAction} 
                            isLargeDevice={isLargeDevice}
                            closeModal={closeModal}
                        />
                        {
                            (appointmentInAction?.type === AppointmentType.TimeBlock)
                            ?
                            <DeleteButton />
                            :
                            null
                        }
                        <div className = {styles.appointment_modal_content}>
                            {
                                (appointmentInAction?.type === AppointmentType.Regular)
                                ?
                                <ClientDetails 
                                    canViewClientInfo={canViewClientInfo}
                                    appointment={appointmentInAction}
                                />
                                :
                                null
                            }
                            <AppointmentDetails 
                                appointment={appointmentInAction}
                                canUserMaintainAgendas={canUserMaintainAgendas}
                            />
                            
                            {
                                (appointmentInAction?.type === AppointmentType.Regular)
                                ?
                                <>
                                    <AppointmentNote 
                                        appointment={appointmentInAction}
                                        appointmentList={appointmentList}
                                    />
                                    <PaymentStatus 
                                        appointment={appointmentInAction}
                                        canUserMaintainAgendas={canUserMaintainAgendas}
                                    />
                                </>
                                :
                                null 
                            }
                            {/* If Payment status is not Paid then we might show this. But there could also be a scenario
                            when payment status is unpaid but appointmentInAction has been discounted by both coupon and gift card.
                            In such cases CouponGiftCard component should not be visible*/}
                            {
                                ((appointmentInAction?.priceAfterDiscount ?? 0) > 0 && !isPaid) &&
                                !(isDiscountedByCoupon && isDiscountedByGiftCard) &&
                                canUserMaintainAgendas
                                ?
                                <CouponGiftCard 
                                    isDiscountedByCoupon={isDiscountedByCoupon}
                                    isDiscountedGiftCard={isDiscountedByGiftCard}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                }
            </ModalContent>
        </Modal>
    );

}

export default AppointmentModal;