import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BsArrowRight } from "react-icons/bs"
import { RootState } from "../../../store";
import { IAppointment } from "../../../types/models/Appointment";
import { toggleActivityByModal, updateState } from "../../../store/calendar/calendarReducer";
import { checkStylistTreatmentExist } from "../../../helpers/others/checkStylistTreatmentExist";
import styles from "./AppointmentModal.module.css";
import InputField from "../common/InputField";

interface CouponGiftCardProps {
    isDiscountedByCoupon?: boolean
    isDiscountedGiftCard?: boolean
}

const CouponGiftCard: React.FC<CouponGiftCardProps> = ({ isDiscountedByCoupon, isDiscountedGiftCard }) => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);

    const { couponCode, giftCardCode, treatmentList, stylistList } = calendarState;
    const appointment = calendarState.appointmentInAction as IAppointment;
    const [validationError, setValidationError] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(updateState({ name, value }));
    }

    const handleClick = (activityType: string) => {

        // Before letting user redeem coupon/gift-card we must make sure that both stylist and treatment exist.
        // Because stylist or treatment might have been deleted from DB so we don't want any trouble.
        const args = {
            appointment,
            treatments: treatmentList,
            stylists: stylistList
        }
        const { message } = checkStylistTreatmentExist(args);
        if(message) return alert(message);

        // Validations
        if(validationError) setValidationError(false);
        if(activityType === "Redeem Coupon" && !couponCode) return setValidationError(true);
        if(activityType === "Redeem Gift Card" && !giftCardCode) return setValidationError(true);
        
        let activityByLabel = "Who is redeeming coupon?";
        if(activityType === "Redeem Gift Card") activityByLabel = "Who is redeeming gift card";

        dispatch(toggleActivityByModal({
            activityType, 
            activityByLabel
        }));

    }

    return (
        <div className = {styles.coupon_gift_card}>
            {
                !isDiscountedByCoupon
                ?
                <div className = {styles.redeem_coupon}>
                    <InputField 
                        customClassName="coupon_gift_card_input"
                        type="text"
                        name="couponCode"
                        placeholder="Enter Coupon Code"
                        value={couponCode}
                        handleChange={handleInputChange}
                        validationError={validationError}
                        validationErrorMessage="coupon code can't be blank"
                    />
                    <button onClick = {() => handleClick("Redeem Coupon")}>
                        <BsArrowRight />
                    </button>
                </div>
                :
                null
            }
            {
                !isDiscountedGiftCard
                ?
                <div className = {styles.redeem_gift_card}>
                    <InputField 
                        customClassName="coupon_gift_card_input"
                        type="text"
                        name="giftCardCode"
                        placeholder="Enter Gift Card Code"
                        value={giftCardCode}
                        handleChange={handleInputChange}
                        validationError={validationError}
                        validationErrorMessage="gift card code can't be blank"
                    />
                    <button onClick = {() => handleClick("Redeem Gift Card")}>
                        <BsArrowRight />
                    </button>
                </div>
                :
                null
            }
        </div>
    );

}

export default CouponGiftCard;