import { useDispatch } from "react-redux";
import { ICalendarAppointment } from "../../types/pages/Calendar";
import { updateState } from "../../store/calendar/calendarReducer";
import styles from "./Calendar.module.css";
import EventIcons from "./EventIcons";

interface IProps {
    events: ICalendarAppointment[]
    date: Date
}

const MonthViewEvents: React.FC<IProps> = ({ events, date }) => {

    const dispatch = useDispatch();
  
    const handleClick = (ev: React.MouseEvent<HTMLDivElement> , event: ICalendarAppointment) => {
        dispatch(updateState({
            name: "appointmentId",
            value: event._id
        }));
        ev.stopPropagation()
    }

    return (
        <>
            {events.map((event, index) => {
                if(index < 3) {
                    return (
                        <div 
                            key = {event._id}
                            className = {styles.month_event}
                            onClick={(ev) => handleClick(ev, event)}
                            style = {{
                                backgroundColor: event.category?.color ?? "white",
                            }}
                        >   
                            <p>{event.clientName}</p>
                            {
                                (event.packages && event.packages.length > 0) || 
                                event.clientNote
                                ?
                                <EventIcons 
                                    event={event}
                                />
                                :
                                null
                            }
                        </div>
                    );
                } else {
                    return null
                }
            })}
        </>
    );

}

export default MonthViewEvents;