import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { RootState } from "../../../store";
import { config } from "../../../Constants";
import { updateState } from "../../../store/calendar/calendarReducer";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import styles from "./AppointmentModal.module.css";

const ResendEmailButton: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { appointmentInAction } = calendarState;

    const resendConfirmationEmailToClient = async() => {

        dispatch(updateState({
            name: "appointmentInAction",
            value: null
        }));

        dispatch(updateState({
            name: "appointmentId",
            value: null
        }));

        dispatch(updateState({
            name: "serverResponseMessage",
            value: "Resending email..."
        }));

        const endpoint = config.url.BACKEND_API_URL + `/appointments/${appointmentInAction?._id}/resend-emails`;
        const authConfig = getAuthTokenConfig();

        try {

            await axios.post(endpoint, {}, authConfig);
            dispatch(updateState({
                name: "serverResponseMessage",
                value: "Resent email successfully"
            }));
            
        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "serverResponseMessage",
                value: message
            }));
        }

    }

    return (
        <button className = {styles.resend_email_button} onClick = {resendConfirmationEmailToClient}>
            Resend Email
        </button>
    );

}

export default ResendEmailButton;