import { useSelector, useDispatch } from "react-redux";
import { IUser } from "../../types/models/User";
import { RootState } from "../../store";
import { 
    updateState,
    updateViewLimit, 
    openEditUserModal, 
    toggleDeletePrompt 
} from "../../store/user-list/userListReducer";
import { FETCH_USER_LIST_DATA } from "../../store/user-list/constants";
import useWindowWidth from "../../hooks/useWindowWidth";
import { getUserAccessItems } from "../../helpers/user-list/getUserAccessItems";
import styles from "./UserTable.module.css";
import DropdownList from "./DropdownList";
import ResponsiveUserTable from "./ResponsiveUserTable";
import ActionButtons from "./ActionButtons";


const UserTable = () => {

    const dispatch = useDispatch();
    const userListState = useSelector((state: RootState) => state.userListState);
    const userState = useSelector((state: RootState) => state.userState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { user } = userState;
    const { userList, userDataFetchInfo } = userListState;
    const { searchText, skip, limit, totalUserCount } = userDataFetchInfo;

    const handleClick = (args: { actionType: string, user: IUser }) => {

        const { actionType, user } = args;

        if(actionType === "Delete") {
            dispatch(toggleDeletePrompt(user));
            return;
        }

        const userAccessItems = getUserAccessItems(user);
        dispatch(updateState({
            name: "userAccessItems",
            value: userAccessItems
        }));
        
        dispatch(openEditUserModal(user))

    }

    const handleDropdownChange = (viewItem: { _id: number, limit: number | string}) => {

        if(totalUserCount <= 5) return;

        dispatch(updateViewLimit(viewItem.limit));

        dispatch({ 
            type: FETCH_USER_LIST_DATA, 
            payload: { 
                tenantId: user?.tenantId, 
                searchText, 
                skip, 
                limit: viewItem.limit
            } 
        });

    }

    return (
        <div className={styles.user_table}> 
            <div className={styles.table_header}>
                <h2>Users</h2>
                <DropdownList 
                    dropdownFor="View"
                    customClassName="view_dropdown_list"
                    data={[
                        { _id: 1, limit: 5 },
                        { _id: 2, limit: 10 },
                        { _id: 3, limit: "All" },
                    ]}
                    nameKey="limit"
                    selectedValue={limit}
                    clickHandler={handleDropdownChange}
                />
            </div>
            {
                isLargeDevice
                ?
                <table>
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>Full Name</th>
                            <th>Email Address</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userList.map(user => {

                            const { _id, userType, fullName, email, isActive } = user;

                            return (
                                <tr key = {_id}>
                                    <td>{userType}</td>
                                    <td>{fullName}</td>
                                    <td>{email}</td>
                                    <td>{isActive ? "Active" : "Inactive"}</td>
                                    <td>
                                        <ActionButtons 
                                            showButtonText={true}
                                            user={user}
                                            canDelete={userType !== "Tenant"}
                                            handleClick={handleClick}
                                        />
                                    </td>
                                </tr>
                            );

                        })}
                    </tbody>
                </table>
                :
                <ResponsiveUserTable 
                    handleClick={handleClick}
                />
            }
        </div>
            
    );

}

export default UserTable;