import { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../auth/UserAuth";
import { IUser } from "../../types/models/User";
import { RootState } from "../../store";
import { StartEndHourMinutes } from "../../types/pages/Calendar";
import { userAccessTypeValues } from "../../data/users";
import { FETCH_CALENDAR_API_DATA, FETCH_APPOINTMENT_DATA } from "../../store/calendar/constants";
import { 
    setSomeInitialState, 
    updateState, 
    openAddAppointmentModal, 
    closeAddAppointmentModal,
    closeChangeTreatmentModal 
} from "../../store/calendar/calendarReducer";
import useWindowWidth from "../../hooks/useWindowWidth";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./Calendar.module.css";
import Modal from "../../components/common/modal/Modal";
import LoadingError from "./common/LoadingError";
import CalendarHeader from "./calendar-header/CalendarHeader";
import DayView from "./DayView";
import WeekView from "./WeekView";
import MonthView from "./MonthView";
import Hours from "./Hours";
import ClientSearch from "./client-search/ClientSearch";
import AppointmentModal from "./appointment-modal/AppointmentModal";
import AddAppointmentModal from "./book-appointment/AddAppointmentModal";
import ClientDetailsModal from "./client-search/ClientDetailsModal";
import ActivityByModal from "./appointment-modal/ActivityByModal";
import ActionMessage from "../../components/common/messages/ActionMessage";
import RescheduleModal from "./reschedule-modal/RescheduleModal";
import SelectLocation from "./SelectLocation";
import DragRescheduleModal from "./DragReschedule";
import ChangeTreatment from "./ChangeTreatment";

const Calendar: React.FC = () => {

    const { windowWidth } = useWindowWidth();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const userState = useSelector((state: RootState) => state.userState);
    const { 
        loading,
        appointmentList,
        selectedLocationIds,
        locationList,
        selectedStylistList,
        clickSelectedStartTime,
        selectedTimes,
        weekViewDates,
        monthViewDates,
        calendarViewType, 
        isRescheduling, 
        isBookingAppointment,
        appointmentId,
        clientInAction,
        showActivityByModal,
        serverResponseMessage,
        dragRescheduleInfo,
        changeTreatmentInfo
    } = calendarState;
    const { user } = userState;

    const { totalMinutes } = useMemo(() => formatDateTime(new Date()), []);
    const [totalMinutesNow, setTotalMinutesNow] = useState(totalMinutes);

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the calendar
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        dispatch(setSomeInitialState());
        dispatch({ type: FETCH_CALENDAR_API_DATA });

    }, [user, navigate, dispatch]);

    useEffect(() => {

        const timer = setTimeout(() => {
            if(selectedLocationIds === "") return;
            dispatch({ type: FETCH_APPOINTMENT_DATA });
        }, 10)

        return () => clearTimeout(timer);
       
    }, [selectedLocationIds, dispatch])


    useEffect(() => {
        const timer = setTimeout(() => setTotalMinutesNow(totalMinutesNow + 1), 60000)
        return () => clearTimeout(timer);
    }, [totalMinutesNow])

    const switchToDayView = useCallback((monthDate: Date) => {
        dispatch(updateState({
            name: "calendarViewType",
            value: "day"
        }));
        dispatch(updateState({
            name: "dayViewDate",
            value: monthDate
        }));
    }, [dispatch])

    const hideActionMessage = () => {
        dispatch(updateState({
            name: "serverResponseMessage",
            value: ""
        }))
    }

    const closeModal = () => {

        if(changeTreatmentInfo.appointmentId) {
            dispatch(closeChangeTreatmentModal());
            return;
        }

        if(dragRescheduleInfo.isDropped) {
            dispatch(updateState({
                name: "dragRescheduleAppointmentId",
                value: null
            }))
            dispatch(updateState({
                name: "dragRescheduleInfo",
                value: {
                    isDropped: false,
                    stylist: null,
                    date: null,
                    startTime: "",
                    rescheduledBy: ""
                }
            }));
            return;
        }

        if(isBookingAppointment) {
            dispatch(closeAddAppointmentModal());
            return;
        }

        dispatch(updateState({
            name: "clientInAction",
            value: null
        }));

        dispatch(updateState({
            name: "clientAppointments",
            value: []
        }));
        
    }

    const checkUserCanBookAppointment = useCallback((stylistId: string) => {

        if(user?.userType === "Admin" && !user?.accessTypeList.includes(userAccessTypeValues.maintainAllAgendas)) return false;
        if(user?.userType === "Stylist" && !user?.accessTypeList.includes(userAccessTypeValues.maintainOwnAgendas)) return false;
    
        const isUserAndStylistTheSame = stylistId === user?._id;
        if(!isUserAndStylistTheSame && !user?.accessTypeList.includes(userAccessTypeValues.maintainAllAgendas)) return false;

        return true;

    }, [user, userAccessTypeValues])
    
    const handleMouseEnter = useCallback((startEndHourMinute: StartEndHourMinutes) => {

        if(!clickSelectedStartTime) return;

        const foundTime = selectedTimes.find(item => {
            return item.startHourMinutes === startEndHourMinute.startHourMinutes
        });

        if(foundTime) return;

        const value = [
            ...selectedTimes,
            {
                ...startEndHourMinute,
                stylist: selectedTimes[0].stylist,
                date: selectedTimes[0].date
            }
        ];

        dispatch(updateState({
            name: "selectedTimes",
            value
        }));

    }, [clickSelectedStartTime, selectedTimes, dispatch])

    const handleMouseDown = useCallback((startEndHourMinute: StartEndHourMinutes, stylist: IUser, date: Date) => {

        const canBookAppointment = checkUserCanBookAppointment(stylist._id);
        if(!canBookAppointment) return;

        dispatch(updateState({ 
            name: "clickSelectedStartTime",
            value: startEndHourMinute.startHourMinutes
        }));

        dispatch(updateState({
            name: "selectedTimes",
            value: [{ ...startEndHourMinute, stylist: stylist.fullName, date }]
        }));

    }, [checkUserCanBookAppointment, dispatch])

    const handleMouseUp = useCallback((stylist: IUser) => {

        const canBookAppointment = checkUserCanBookAppointment(stylist._id);
        if(!canBookAppointment) return;

        dispatch(updateState({ 
            name: "clickSelectedStartTime",
            value: ""
        }));

        if(!selectedTimes.length) return;

        const appointment_date = selectedTimes[0].date;
        const startTime = selectedTimes[0].startHourMinutes;
        const endTime = selectedTimes[selectedTimes.length - 1].endHourMinutes;

        if(!stylist) return;

        dispatch(openAddAppointmentModal({
            appointment_date,
            stylist,
            startTime,
            endTime
        }));

    }, [selectedTimes, checkUserCanBookAppointment, dispatch])

    const handleDragOver = useCallback((event: React.DragEvent) => {
        event.preventDefault();
    }, [])

    const handleDrop = useCallback((startHourMinutes: string, stylistId: string, date: Date) => {
        const foundStylist = selectedStylistList.find(item => item._id === stylistId);
        dispatch(updateState({
            name: "dragRescheduleInfo",
            value: {
                ...dragRescheduleInfo,
                isDropped: true,
                startTime: startHourMinutes,
                stylist: foundStylist,
                date
            }
        }));
    }, [selectedStylistList, dispatch])

    return (
        <>
            <div className = {styles.calendar_route}>
                {
                    serverResponseMessage
                    &&
                    <ActionMessage 
                        actionMessage = {serverResponseMessage}
                        hideActionMessage={hideActionMessage}
                    />
                }
                <div className={styles.search_select_location}>
                    <ClientSearch />
                    {locationList.length > 1 ? <SelectLocation />: null}
                </div>
                <div className = {styles.calendar}>
                    {
                        loading
                        ?
                        <LoadingError 
                            message="Fetching calendar data..."
                        />
                        :
                        (selectedStylistList.length < 1 || locationList.length < 1)
                        ?
                        <LoadingError 
                            message="No calendar to show"
                        />
                        :
                        <>
                            <CalendarHeader />
                            <div className = {styles.main_calendar}>
                                {calendarViewType !== "month" && <Hours />}
                                {
                                    calendarViewType === "day" 
                                    ?
                                    <DayView 
                                        totalMinutes={totalMinutesNow}
                                        handleMouseUp={handleMouseUp}
                                        handleMouseDown={handleMouseDown}
                                        handleMouseEnter={handleMouseEnter}
                                        handleDragOver={handleDragOver}
                                        handleDrop={handleDrop}
                                    />
                                    :
                                    null
                                }
                                {
                                    calendarViewType === "week"
                                    ?
                                    <WeekView 
                                        selectedStylistList={selectedStylistList}
                                        appointmentList={appointmentList}
                                        weekViewDates={weekViewDates}
                                        totalMinutes={totalMinutesNow}
                                        switchToDayView={switchToDayView}
                                        handleMouseUp={handleMouseUp}
                                        handleMouseDown={handleMouseDown}
                                        handleMouseEnter={handleMouseEnter}
                                        handleDragOver={handleDragOver}
                                        handleDrop={handleDrop}
                                    />
                                    :
                                    null
                                }
                                {
                                    calendarViewType === "month"
                                    ? 
                                    <MonthView 
                                        selectedStylistList={selectedStylistList}
                                        appointmentList={appointmentList}
                                        weekViewDates={weekViewDates}
                                        monthViewDates={monthViewDates}
                                        switchToDayView={switchToDayView}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
            {appointmentId ? <AppointmentModal /> : null}
            {
                dragRescheduleInfo.isDropped 
                ? 
                <Modal 
                    minWidth={windowWidth <= 800 ? "95%" : "30%"}
                    minHeight={windowWidth <= 800 ? "30%" : "30%"}
                    modalContent={<DragRescheduleModal />}
                    onCloseModal={closeModal}
                />
                : null
            }
            {
                changeTreatmentInfo.appointmentId 
                ? 
                <Modal 
                    minWidth={windowWidth <= 800 ? "95%" : "35%"}
                    minHeight={windowWidth <= 800 ? "30%" : "40%"}
                    modalContent={<ChangeTreatment />}
                    onCloseModal={closeModal}
                />
                : null
            }
            {
                isBookingAppointment 
                ? 
                <Modal 
                    modalContent={
                        <AddAppointmentModal />
                    }
                    onCloseModal={closeModal}
                />
                : 
                null
            }
            {
                (clientInAction && !isBookingAppointment)
                ?
                <Modal 
                    minWidth="50%"
                    zIndex="3"
                    modalContent={<ClientDetailsModal />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {showActivityByModal ? <ActivityByModal /> : null}
            {isRescheduling ? <RescheduleModal /> : null}
        </>
    );

}

export default Calendar;