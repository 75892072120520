import axios from "axios";
import { config } from "../../../Constants";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";

export const fetchCalendarApiData = async(tenantId?: number) => {

    const endpoint = `${config.url.BACKEND_API_URL}/appointments/${tenantId}/calendar-data`;
    const authConfig = getAuthTokenConfig()

    const response = await axios.get(endpoint, authConfig);
    return response.data;

}