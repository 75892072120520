import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { 
    updateState, 
    toggleAppointmentClientDeletePrompt
} from "../../store/appointment-clients/appointmentClientReducer";
import { FETCH_APPOINTMENT_CLIENT_DATA } from "../../store/appointment-clients/constants";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import DeletePrompt from "../../components/common/prompts/DeletePrompt";

const DeleteAppointmentClient = () => {

    const dispatch = useDispatch();
    const appointmentClientState = useSelector((state: RootState) => state.appointmentClientState);
    const { appointmentClientInAction } = appointmentClientState;

    const closeDeletePrompt = () => {
        dispatch(toggleAppointmentClientDeletePrompt(null))
    }

    const deleteProduct = async() => {

        const endpoint = config.url.BACKEND_API_URL + `/clients/appointment-clients/${appointmentClientInAction?._id}/delete`;
        const authConfig = getAuthTokenConfig();

        dispatch(toggleAppointmentClientDeletePrompt(null));

        dispatch(updateState({
            name: "actionMessage",
            value: "Deleting..."
        }));

        try {

            const response = await axios.delete(endpoint, authConfig);

            dispatch(updateState({
                name: "actionMessage",
                value: response.data.message
            }));

            dispatch({ type: FETCH_APPOINTMENT_CLIENT_DATA })

        } catch(error) {
            const { message } = handleApiError(error);
            dispatch(updateState({
                name: "actionMessage",
                value: message
            }));
        }

    }
    
    return (
        <DeletePrompt 
            warningText={<p>Are you sure want to delete <span>{appointmentClientInAction?.fullName}</span></p>}
            closeDeletePrompt={closeDeletePrompt}
            deleteItem={deleteProduct}
        />
    );

}

export default DeleteAppointmentClient;