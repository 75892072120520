import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { UserAuth } from "../../auth/UserAuth";
import { FETCH_GIFT_CARD_DATA } from "../../store/gift-card/constants";
import { updateState, addBuyerInfo, addReceiverInfo } from "../../store/gift-card/giftCardReducer";
import useWindowWidth from "../../hooks/useWindowWidth";
import styles from "./index.module.css";
import SearchAdd from "./SearchAdd";
import GiftCardTable from "./GiftCardTable";
import GiftCardDetails from "./GiftCardDetails";
import BuyGiftCard from "./BuyGiftCard";
import Modal from "../../components/common/modal/Modal";


const GiftCardList = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const giftCardState = useSelector((state: RootState) => state.giftCardState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { searchText, isBuying, giftCardInAction } = giftCardState;
    const { windowWidth } = useWindowWidth();

    useEffect(() => {

        if(!UserAuth()) {
            navigate("/");
            return;
        }

        if(!user) return;

        // User must be on trial period or must have bought a sub plan to access the treatments
        if(!user.stripeSubscription && !user.isOnTrialPeriod) {
            navigate("/");
            return;
        }

        const delayAPICall = setTimeout(() => {
            dispatch({ type: FETCH_GIFT_CARD_DATA })
        }, 500);

        return () => clearTimeout(delayAPICall);
        
    }, [user, searchText, dispatch, navigate]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {

        const { name, value, dataset } = event.target;

        if(dataset.type === "buyer") {
            dispatch(addBuyerInfo({ name, value }));
            return;
        }

        if(dataset.type === "receiver") {
            dispatch(addReceiverInfo({ name, value }));
            return;
        }

        dispatch(updateState({ name, value }));

    }

    const closeModal = () => {
        if(isBuying) {
            dispatch(updateState({
                name: "isBuying",
                value: false
            }));
        }
        dispatch(updateState({
            name: "giftCardInAction",
            value: null
        }));
    }

    return (
        <div 
            className={styles.gift_card_page}
            style={{
                overflow: isBuying ? "hidden" : undefined
            }}
        >
            <SearchAdd handleChange={handleChange} />
            <GiftCardTable />
            {
                (!isBuying && giftCardInAction)
                ?
                <Modal 
                    modalContent={<GiftCardDetails />}
                    onCloseModal={closeModal}
                />
                :
                null
            }
            {
                isBuying
                ?
                <Modal 
                    minWidth={windowWidth <= 1000 ? "97%": "40%"}
                    maxHeight="90vh"
                    minHeight="500px"
                    modalContent={
                        <BuyGiftCard 
                            handleChange={handleChange} 
                        />
                    }
                    onCloseModal={closeModal}
                />
                :
                null
            }
        </div>
    );

}

export default GiftCardList;
