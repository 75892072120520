import { 
    TimeSlotGroup, 
    StartEndHourMinutes 
} from "../../types/pages/Calendar";

const intervalCount = 4;
const totalHourCount = 24;
const timeSlotGroups: TimeSlotGroup[] = []

for(let x = 7; x < totalHourCount; x++) {

    let hour: string | number = x;
    let nextHour: string | number = x + 1;
    if(hour < 10) hour = "0" + hour;

    if(hour === 23) nextHour = 0;
    if(nextHour < 10) nextHour = "0" + nextHour;

    const startEndHourMinutes: StartEndHourMinutes[] = [];

    for(let y = 1; y <= intervalCount; y++) {

        const intervalMinute = 60 / intervalCount; 

        let startHourMinutes = `${hour}:${(intervalMinute * y) - intervalMinute}`;
        let endHourMinutes = `${hour}:${intervalMinute * y}`;

        if(y === 1) startHourMinutes = `${hour}:00`;
        if(y === intervalCount) endHourMinutes = `${nextHour}:00`;

        startEndHourMinutes.push({
            startHourMinutes,
            endHourMinutes
        });
    }

    const xxx = {
        hour: `${hour}`,
        nextHour: `${nextHour}`,
        startEndHourMinutes
    }

    timeSlotGroups.push(xxx);

}

export { timeSlotGroups };
