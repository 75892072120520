import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { toggleActivityByModal, updateState } from "../../../store/calendar/calendarReducer";
import { checkStylistTreatmentExist } from "../../../helpers/others/checkStylistTreatmentExist";
import styles from "./AppointmentModal.module.css";
import InputField from "../common/InputField";
import { ICalendarAppointment } from "../../../types/pages/Calendar";

function AddPayment() {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { 
        appointmentInAction, 
        treatmentList, 
        stylistList, 
        paymentMethod, 
        paymentAmount 
    } = calendarState;
    const [validationError, setValidationError] = useState<boolean>(false);

    const appointment = appointmentInAction as ICalendarAppointment;

    useEffect(() => {

        if(paymentAmount) return;

        const paidAmount = appointment.paymentList?.reduce((total, payment) => {
            return total + payment.amount
        }, 0);

        const amount = Number((paidAmount ?? 0).toFixed(2));
        const priceAfterDiscount = Number(appointment.priceAfterDiscount.toFixed(2));

        if(amount > 0 && amount < priceAfterDiscount) {
            dispatch(updateState({
                name: "paymentAmount",
                value: Number((priceAfterDiscount - amount).toFixed(2))
            }));
        }

    }, [appointment, paymentAmount, dispatch])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        dispatch(updateState({ name, value }));
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        // Before letting user make payment we must make sure that both stylist and treatment exist.
        // Because stylist or treatment might have been deleted from DB so we don't want any trouble.
        const args = {
            appointment,
            treatments: treatmentList,
            stylists: stylistList
        }
        const { message } = checkStylistTreatmentExist(args);
        if(message) return alert(message);


        if(validationError) setValidationError(false);
        if(!paymentMethod || !paymentAmount) return setValidationError(true);

        // Amount must be a number
        const newPaymentAmount = Number(paymentAmount);
        if(isNaN(newPaymentAmount)) return alert("Amount must be a number");
        
        dispatch(toggleActivityByModal({
            activityType: "Pay", 
            activityByLabel: "Who is updating payment?"
        }));

    }

    return (
        <div className = {styles.add_payment}>
            <h5>Make Payment</h5>
            <div className = {styles.add_payment_content}>
                <div className = {styles.add_payment_inputs}>
                    <InputField 
                        type="text"
                        name="paymentMethod"
                        placeholder="Payment Method"
                        value={paymentMethod}
                        handleChange={handleInputChange}
                        validationError={validationError}
                        validationErrorMessage="method can't be blank"
                    />
                    <InputField 
                        type="text"
                        name="paymentAmount"
                        placeholder="Amount"
                        value={paymentAmount}
                        handleChange={handleInputChange}
                        validationError={validationError}
                        validationErrorMessage="amount can't be blank"
                    />
                </div>
                <div className = {styles.add_payment_button}>
                    <button onClick = {handleClick}>Pay</button>
                </div>
            </div>
        </div>
    );

}

export default AddPayment;