import { useSelector } from "react-redux";
import { IUser } from "../../types/models/User";
import { StartEndHourMinutes } from "../../types/pages/Calendar";
import { RootState } from "../../store";
import styles from "./Calendar.module.css";
import TimeIndicator from "./TimeIndicator";
import Events from "./Events";
import TimeSlotGroup from "./TimeSlotGroup";

interface DayViewProps {
    totalMinutes: number
    handleMouseUp: (stylist: IUser) => void
    handleMouseDown: (startEndHourMinute: StartEndHourMinutes, stylist: IUser, date: Date) => void
    handleMouseEnter: (startEndHourMinute: StartEndHourMinutes) => void
    handleDragOver: (event: React.DragEvent) => void
    handleDrop: (startHourMinutes: string, stylistId: string, date: Date) => void
}

const DayView: React.FC<DayViewProps> = ({ 
    totalMinutes, 
    handleMouseUp,
    handleMouseDown,
    handleMouseEnter,
    handleDragOver, 
    handleDrop 
}) => {

    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { selectedStylistList, appointmentList, dayViewDate } = calendarState;

    const timeIndicatorMinWidth = (selectedStylistList.length * 25) < 100 ? 100 : selectedStylistList.length * 25;

    return (  
        <div className={styles.day_view}>
            {
                (totalMinutes - 240) > 0
                ?
                <TimeIndicator 
                    totalMinutes={totalMinutes} 
                    style={{
                        minWidth: `${timeIndicatorMinWidth}%`
                    }}
                />
                :
                null
            }
            {selectedStylistList.map(stylist => {

                const stylistAppointments = appointmentList.filter(item => item.stylist._id === stylist._id);

                return (
                    <div 
                        className = {styles.day_times_container} 
                        key = {stylist._id}
                        style = {{
                            width: `${100 / selectedStylistList.length}%`
                        }}
                    >
                        <div className = {styles.stylist_name}>
                            {stylist.fullName}
                        </div>
                        <div className = {styles.timeslot_groups_container}>
                            <Events 
                                events = {stylistAppointments}
                                date = {dayViewDate}
                            />
                            <TimeSlotGroup 
                                date = {dayViewDate}
                                stylist={stylist}
                                selectedStylistList={selectedStylistList}
                                handleMouseUp={handleMouseUp}
                                handleMouseDown={handleMouseDown}
                                handleMouseEnter={handleMouseEnter}
                                handleDragOver={handleDragOver}
                                handleDrop={handleDrop}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default DayView;