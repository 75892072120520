import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { config } from "../../../Constants";
import { updateState } from "../../../store/calendar/calendarReducer";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../../helpers/error-handlers/handleApiError";

type Return = {
    loading: boolean
    errorMsg: string | undefined
}

export const useFetchSingleAppointment = (appointmentId: string): Return => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const fetchSingleAppointment = useCallback(async() => {

        const endpoint = config.url.BACKEND_API_URL + `/appointments/single/${appointmentId}`;
        const authConfig = getAuthTokenConfig();

        try {

            setLoading(true);

            const response = await axios.get(endpoint, authConfig);

            dispatch(updateState({
                name: "appointmentInAction",
                value: response.data.appointment
            }))
           
            setLoading(false);

        } catch(error: any) {
            setLoading(false);
            const { message } = handleApiError(error);
            setErrorMsg(message)
        }

    }, [])

    useEffect(() => {
        fetchSingleAppointment()
    }, [])

    return {
        loading,
        errorMsg
    };

}