import { useDispatch } from "react-redux";
import { AppointmentType } from "../../types/models/Appointment";
import { ICalendarAppointment } from "../../types/pages/Calendar";
import { updateState } from "../../store/calendar/calendarReducer";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import { calculateAppointmentPosition } from "../../helpers/calendar/calculateAppointmentPosition";
import styles from "./Calendar.module.css";
import EventIcons from "./EventIcons";

interface IProps {
    events: ICalendarAppointment[]
    date: Date
}

const Events: React.FC<IProps> = ({ events, date }) => {

    const dispatch = useDispatch();

    const handleClick = (ev: React.MouseEvent<HTMLDivElement>, event: ICalendarAppointment) => {
        dispatch(updateState({
            name: "appointmentId",
            value: event._id
        }));
        ev.stopPropagation();
    }

    const { dateString } = formatDateTime(date);

    const handleDragStart = (appointmentId: string) => {
        dispatch(updateState({
            name: "dragRescheduleAppointmentId",
            value: appointmentId
        }))
    }

    return (
        <div className = {styles.events_container}>
            {events.map(event => {

                const { width, leftPosition } = calculateAppointmentPosition(event, events);

                const indexOfT = event.startTime.indexOf("T");
                const eventDateString = event.startTime.substring(0, indexOfT);

                const eventStartTime = event.startTime.substring(indexOfT + 1, event.startTime.length);

                const hours = Number(eventStartTime.substring(0, eventStartTime.indexOf(":")));
                const minutes = Number(eventStartTime.substring(eventStartTime.indexOf(":") + 1, eventStartTime.length));

                const topPosition = (hours - 7 + (minutes / 60)) * 100;

                // In the stylesheet height of each timeslot is 25px. And each time is containing 15 minutes of
                // appointment duration. So event height per duration minute will be 25 / 15.
                const eventHeight = (25 / 15) * event.duration; 

                if(dateString === eventDateString) {

                    return (
                        <div 
                            key = {event._id}
                            className = {styles.event}
                            draggable={true}
                            onClick={(ev) => handleClick(ev, event)}
                            onDragStart={() => handleDragStart(event._id)}
                            style = {{
                                width: `${width}%`,
                                backgroundColor: event.category?.color ?? "white",
                                left: `${leftPosition}%`,
                                top: `${topPosition}px`,
                                height: `${eventHeight}px`
                            }}
                        >
                            <p>{event.clientName}</p>
                            {
                                event.type === AppointmentType.TimeBlock
                                ?
                                <p>{event.title} with {event.stylist.fullName}</p>
                                :
                                <p>{event.treatment?.title} with {event.stylist.fullName}</p>
                            }
                            {
                                (event.packages && event.packages.length > 0) || 
                                event.clientNote
                                ?
                                <EventIcons 
                                    event={event}
                                />
                                :
                                null
                            }
                        </div>
                    );

                } else {
                    return null;
                } 
            })}
        </div>
    );

}

export default Events;