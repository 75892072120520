import styles from "./AppointmentModal.module.css";

const AppointmentStatus: React.FC<{ status?: string }> = ({ status }) => {

    return (
        <div className = {styles.appointment_status_info}>
            <h5>Appointment Status:</h5>
            <span>{status}</span>
        </div> 
    );

}

export default AppointmentStatus;