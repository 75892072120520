import { AppointmentDiscountType } from "../../../types/models/Appointment";
import { ICalendarAppointment } from "../../../types/pages/Calendar";
import styles from "./AppointmentModal.module.css";
import Amount from "./Amount";

const PaymentBreakdown: React.FC<{ appointment: ICalendarAppointment }> = ({ appointment }) => {

    const price = (appointment.treatment?.price ?? 0)

    let otherDiscounts = 0;
    let giftCardDiscount = 0;

    for(const discount of (appointment.discountList ?? [])) {
        if(discount.type === AppointmentDiscountType.GiftCard) {
            giftCardDiscount += discount.amount
        } else {
            otherDiscounts += discount.amount
        }
    }

    const paidAmount = appointment.paymentList?.reduce((total, payment) => {
        return total + payment.amount
    }, 0)   

    const toBePaidAmount = price - giftCardDiscount - otherDiscounts - (paidAmount ?? 0);

    return (
        <div className={styles.payment_breakdown}>
            <table>
                <tbody>
                    <Amount 
                        title="Price"
                        value={`€${price.toFixed(2)}`}
                    />
                    {
                        otherDiscounts > 0
                        &&
                        <Amount 
                            title="Discounts"
                            value={`€${otherDiscounts.toFixed(2)}`}
                        />
                    }
                    {
                        giftCardDiscount > 0
                        &&
                        <Amount 
                            title="Paid By Gift Card"
                            value={`€${giftCardDiscount.toFixed(2)}`}
                        />
                    }
                    {
                        appointment.paymentList?.map(item => {
                            return (
                                <Amount 
                                    key={`${item.date.iso}-${item.method}-${item.amount}`}
                                    title={`Paid By ${item.method}`}
                                    value={`€${item.amount.toFixed(2)}`}
                                />
                            );
                        })
                    }
                    {
                        toBePaidAmount > 0
                        ?
                        <Amount 
                            title="To Be Paid"
                            value={`€${toBePaidAmount.toFixed(2)}`}
                        />
                        :
                        null
                    }
                </tbody>
            </table>
        </div>
    );

}

export default PaymentBreakdown;