import axios from "axios";
import { FetchAppointmentQueryParams } from "../../../types/pages/Calendar";
import { config } from "../../../Constants";
import { getAuthTokenConfig } from "../../../helpers/others/getAuthTokenConfig";

export const fetchAppointments = async(payload: FetchAppointmentQueryParams) => {

    const { 
        tenantId,
        startDateString, 
        endDateString,
        selectedLocationIds = "",
        selectedStylistIds = "" 
    } = payload;

    const param1 = `startDateString=${startDateString}&endDateString=${endDateString}&`;
    const param2 = `locationIds=${selectedLocationIds}&stylistIds=${selectedStylistIds}`
    
    const params = param1 + param2
    
    const endpoint = `${config.url.BACKEND_API_URL}/appointments/${tenantId}/calendar-appointments?${params}`;

    const authConfig = getAuthTokenConfig()
  
    const response = await axios.get(endpoint, authConfig);

    return response.data;

}