import axios from "axios";
import { config } from "../../Constants";
import { handleApiError } from "../error-handlers/handleApiError";
import { getAuthTokenConfig } from "../others/getAuthTokenConfig";

type ArgsType = {
    appointment_id: string,
    activityBy: string,
    doNotSendEmailToClient: boolean, 
    selectedStylist: string,
    selectedStylistId: string,
    selectedDateTime: string
}

export const rescheduleAppointment = async (args: ArgsType) => {

    const requestBody = {
        stylistId: args.selectedStylistId,
        dateTime: args.selectedDateTime,
        activityBy: args.activityBy, 
        doNotSendEmailToClient: args.doNotSendEmailToClient,
    }

    const endpoint = config.url.BACKEND_API_URL + `/appointments/${args.appointment_id}/reschedule`;
    const authConfig = getAuthTokenConfig();

    try {

        const response = await axios.patch(endpoint, requestBody, authConfig);
        const message: string = response.data.message;

        return message;

    } catch(error: any) {
       const { message } = handleApiError(error);
       return message;
    }

}