import { ICalendarAppointment } from "../../types/pages/Calendar";
import { IUser } from "../../types/models/User";
import { userAccessTypeValues } from "../../data/users";

export const getUserAccess = (user: IUser | null, appointment: ICalendarAppointment | null) => {

    const isTenant = user?.userType === "Tenant";
    const isStylistOwnAppointment = user?._id === appointment?.stylist?._id;

    const canUserMaintainOwnAgendas = isStylistOwnAppointment && user?.accessTypeList.includes(userAccessTypeValues.maintainOwnAgendas);
    const canUserMaintainAllAgendas = isTenant || user?.accessTypeList.includes(userAccessTypeValues.maintainAllAgendas);

    // Tenant can see client info. Or iif user is stylist and can view own client details then he/she can see client info.
    // Or if user is admin and can maintain others' agendas then he/she can see client info.
    const canViewClientInfo = isTenant || 
        (isStylistOwnAppointment && user?.accessTypeList.includes(userAccessTypeValues.viewOwnClientDetails)) || 
        user?.accessTypeList.includes(userAccessTypeValues.maintainAllClientDetails)
    const canUserMaintainAgendas = canUserMaintainOwnAgendas || canUserMaintainAllAgendas;

    return {
        canViewClientInfo,
        canUserMaintainAgendas
    }

}