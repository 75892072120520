import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { RootState } from "../../store";
import { config } from "../../Constants";
import { FETCH_APPOINTMENT_CLIENT_DATA } from "../../store/appointment-clients/constants";
import { 
    updateState, 
    addNewAppointmentClientInfo, 
    closeAddEditAppointmentClientModal 
} from "../../store/appointment-clients/appointmentClientReducer";
import { validateEmailAddress } from "../../helpers/validators/validateEmailAddress";
import { getAuthTokenConfig } from "../../helpers/others/getAuthTokenConfig";
import { handleApiError } from "../../helpers/error-handlers/handleApiError";
import styles from "./index.module.css";
import InputField from "../../components/common/input-fields/InputField";
import TextAreaInputField from "../../components/common/input-fields/TextAreaInputField";
import ValidationErrorMessage from "../../components/common/messages/ValidationErrorMessage";
import SaveButton from "./SaveButton";

const AddEditAppointmentClient: React.FC = () => {

    const { tenantId } = useParams();

    const dispatch = useDispatch();
    const appointmentClientState = useSelector((state: RootState) => state.appointmentClientState);
    const {
        isAdding,
        appointmentClientInAction,
        newAppointmentClientInfo,
        validationError, 
    } = appointmentClientState;

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(addNewAppointmentClientInfo({ name, value }));
    }

    const saveAppointmentClient = async(event: React.MouseEvent<HTMLButtonElement> | SubmitEvent) => {
        
        event.preventDefault();

        if(errorMsg) setErrorMsg(undefined);

        const { fullName, email, phone, note } = newAppointmentClientInfo;

        if(!fullName || !email || !phone) {
            dispatch(updateState({
                name: "validationError",
                value: true
            }));
            return;
        }
        
        const { emailValMessage, isEmailValid } = validateEmailAddress(email);
        if(!isEmailValid) {
            setErrorMsg(emailValMessage);
            return;
        }

        setLoading(true);

        const axiosConfig = {
            baseURL: config.url.BACKEND_API_URL + `/clients/${tenantId}/appointment-clients`,
            url: isAdding ? `/create` : `/${appointmentClientInAction?._id}/edit`,
            method: isAdding ? "post" : "patch",
            headers: getAuthTokenConfig().headers,
            data: {
                fullName,
                email,
                phone,
                note: note === "" ? null : note
            }
        }

        try {

            const response = await axios(axiosConfig);

            setLoading(false);
            dispatch(closeAddEditAppointmentClientModal());
            dispatch(updateState({
                name: "actionMessage",
                value: response.data.message
            }));

            dispatch({ type: FETCH_APPOINTMENT_CLIENT_DATA })
          
        } catch(error) {
            const { message } = handleApiError(error);
            setErrorMsg(message);
            setLoading(false);
        }

    }

    return (
        <div className={styles.add_edit_appointment_client}>
            <h2>{isAdding ? "Create New Client" : "Edit Client"}</h2>
            <form>
                <InputField 
                    labelText="Full Name"
                    name="fullName"
                    value={newAppointmentClientInfo.fullName}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="full name can't be blank"
                />
                <InputField 
                    labelText="Email"
                    type="email"
                    name="email"
                    value={newAppointmentClientInfo.email}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="email can't be blank"
                />
                <InputField 
                    labelText="Phone"
                    name="phone"
                    value={newAppointmentClientInfo.phone}
                    required={true}
                    handleChange={handleChange}
                    validationError={validationError}
                    validationErrorMessage="phone can't be blank"
                />
                <TextAreaInputField 
                    labelText="Note"
                    rows={4}
                    columns={3}
                    name="note"
                    value={newAppointmentClientInfo.note}
                    handleChange={handleChange}
                />
                {errorMsg ? <ValidationErrorMessage message={errorMsg}/> : null}
                <SaveButton 
                    disabled={loading}
                    onClick={saveAppointmentClient}
                />
            </form>
        </div>
    );

}

export default AddEditAppointmentClient;