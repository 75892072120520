import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./ResponsiveItemDetails.module.css";
import ItemHeader from "./ItemHeader";
import ItemContent from "./ItemContent";

const ResponsiveAppointmentTable: React.FC= () => {

    const reportState = useSelector((state: RootState) => state.reportState);
    const { appointmentList } = reportState;
   
    return (
        <>
            {appointmentList.map(appointment => {

                const { 
                    _id, 
                    appointment_date,
                    stylist, 
                    status, 
                    category,
                    treatment,
                    paymentList
                } = appointment;
                
                const { day, month, year } = formatDateTime(new Date(appointment_date * 1000))

                const methods = paymentList?.map(item => item.method).join(", ");

                return (
                    <div 
                        key = {_id}
                        className = {styles.responsive_appointment_item}
                    >
                        <ItemHeader 
                            title={category?.title}
                            content={status}
                        />
                        <ItemContent 
                            title={treatment?.title}
                            content={stylist.fullName}
                            otherInfo={[`${month} ${day}, ${year}`, (methods ?? "")]}
                        />
                       
                    </div>
                );
            })}
        </>
    );

}

export default ResponsiveAppointmentTable;