import { IoMdClose } from "react-icons/io";
import styles from "./Modal.module.css";

interface ModalProps {
    minWidth?: string
    minHeight?: string
    maxHeight?: string
    zIndex?: string
    modalContent: React.ReactNode
    onCloseModal: () => void
}

const Modal: React.FC<ModalProps> = (props) => {

    const { 
        minWidth, 
        minHeight, 
        maxHeight,
        zIndex, 
        modalContent, 
        onCloseModal 
    } = props;

    return (
        <div 
            className={styles.modal}
            style={{ zIndex }}
        >
            <div className={styles.modal_background}></div>
            <div 
                className={styles.modal_content} 
                style={{ 
                    minWidth,
                    minHeight,
                    maxHeight
                }}
            >
                <button
                    className={styles.modal_cls_btn}
                    onClick={onCloseModal}
                >
                    <IoMdClose 
                        size={"1.4rem"}
                    />
                </button>
                {modalContent}
            </div>
        </div>
    );
}

export default Modal;