import { ICategory } from "./Category";
import { ILocation } from "./Location";
import { ITreatment } from "./Treatment";
import { IUser } from "./User";
import { DateMetaData } from "../common/DateTime"

export enum AppointmentType {
    Regular = "regular",
    TimeBlock = "time_block"
}

export enum AppointmentStatus {
    Confirmed = "Confirmed",
    Cancelled = "Cancelled"
}

export enum AppointmentPaymentMethod {
    CreditCard = "credit_card",
    Ideal = "ideal",
    Paypal = "paypal",
    PayLater = "pay_later",
    Card = "card",
    Cash = "cash",
    Online = "online"
}

export enum AppointmentDiscountType {
    Package = "package",
    Coupon = "coupon",
    Time = "time",
    GiftCard = "gift_card"
}

export interface AppointmentPayment {
    date: DateMetaData
    method: AppointmentPaymentMethod
    amount: number
    online: boolean
    stripePaymentIntentId?: string
    paypalPaymentCaptureId?: string
}

export interface AppointmentRefund {
    date: DateMetaData
    reason: string
    amount: number
    partial: boolean
}

// code for coupon and gift card and title for package.
export interface AppointmentDiscount {
    date: DateMetaData
    type: string
    typeId?: string
    amount: number
    code?: string
    title?: string
}

export interface AppointmentCancellation {
    date: DateMetaData
    reason: string
    cancelledBy: string
}

export interface AppointmentActivity {
    createdAt: number
    description: string
}

// For time-block appointments locationItem and treatmentItem can be null
export interface IAppointment {
    _id: string
    __v: number
    createdAt: number
    tenantId: number
    appointment_date: number
    type: string
    title?: string
    startTime: string
    endTime: string
    location: ILocation | null
    category: ICategory | null
    treatment: ITreatment | null
    stylist: IUser
    duration: number
    priceAfterDiscount: number
    paymentList?: AppointmentPayment[]
    discountList?: AppointmentDiscount[]
    cancellation?: AppointmentCancellation
    refund?: AppointmentRefund
    clientName: string
    clientEmail: string
    clientPhone: string
    status: string
    note?: string
    activities: AppointmentActivity[]
}