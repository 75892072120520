import { ICalendarAppointment } from "../../types/pages/Calendar";

export const getAppointmentPaymentStatus = (appointment: ICalendarAppointment) => {

    const paidAmount = appointment.paymentList?.reduce((total, payment) => {
        return total + payment.amount;
    }, 0) ?? 0;

    const isDiscounted = appointment.priceAfterDiscount === 0;
    const isPartiallyPaid = paidAmount > 0 && paidAmount < appointment.priceAfterDiscount;
    const isUnpaid = paidAmount === 0 && appointment.priceAfterDiscount > 0;

    const status = isDiscounted ? "discounted" : (isPartiallyPaid ? "partially_paid" : isUnpaid ? "unpaid" : "paid");

    return status;

}