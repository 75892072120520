import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import styles from "./ResponsiveItemDetails.module.css";
import ItemHeader from "./ItemHeader";
import ItemContent from "./ItemContent";

const ResponsivePaymentTable: React.FC = () => {

    const reportState = useSelector((state: RootState) => state.reportState);
    const { appointmentList } = reportState;

    return (
        <>
            {appointmentList.map(appointment => {
                const { 
                    _id,
                    createdAt,
                    treatment,
                    clientName,
                    paymentList
                } = appointment;

                const { day, month, year } = formatDateTime(new Date(createdAt * 1000));

                const paymentMadeOnline = paymentList?.find(item => item.online);

                if(!paymentMadeOnline) return null;

                return (
                    <div 
                        key = {_id}
                        className = {styles.responsive_appointment_item}
                    >
                        <ItemHeader 
                            title={treatment?.title}
                            content={`€${treatment?.price.toFixed(2)}`}
                        />
                        <ItemContent 
                            title={`${month} ${day}, ${year}`}
                            content={`€${paymentMadeOnline.amount.toFixed(2)}`}
                            otherInfo={[
                                `Client Name: ${clientName}`,
                                `Payment Method: ${paymentMadeOnline.method}`,
                            ]}
                        />
                        
                    </div>
                );
            })}
        </>
    );

}

export default ResponsivePaymentTable;